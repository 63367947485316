@import "~assets/scss/colors";

#recent-works {
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 70px;

  .row {
    margin-top: 20px;
  }
}
