@import "~assets/scss/colors";

#intro {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;

  .slick-slider, .main-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .slick-slider,
  .slick-list,
  .slick-track {
      height: 100%;
  }

  .slick-track {
    display: flex;
  }
  .slick-track .slick-slide {
    display: flex;
    height: auto;
  }

  .main-wrapper {
    position: absolute;
    top: 90px;
    left: 20px;
    width: calc(100% - 40px);
    height: calc(100% - 110px);
    overflow: hidden;

    .main-inner-wrapper {
      padding-top: calc(100vh - 40px);

      .children {
        background-color: $white;
        position: relative;
        z-index: 9
      }
    }
  }

  .main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    color: $white;
    text-align: center;

    h1 {
      text-transform: uppercase;
      font-size: 64px;
      display: inline-block;
      margin: 0;
      margin-bottom: 5px;
      line-height: initial;
      font-weight: bold;

      @media(max-width: 767px) {
        font-size: 32px;
      }
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: saturate(0%);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  >.overlay {
    background-color: $blue;
    mix-blend-mode: color;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
  }

  .overlay-2 {
    background-color: $pink;
    mix-blend-mode: multiply;
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    position: absolute;
  }

  .glitch {
    position: relative;

    .before, .after {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 255, .1);
      overflow: hidden;
      top: 0;
    }

    .before {
      left: 2px;
      text-shadow: -3px 0 $pink;
    }

    .after {
      left: -1px;
      text-shadow: -2px 0 $blue;
    }

    &.animate {
      .before, .after {
        animation-duration: 2s;
        animation-timing-function: linear;
        animation-delay: 0s;
        animation-iteration-count: infinite;
        animation-direction: reverse-alternate;
      }

      .before {
        animation-name: glitch-animation-1;
      }

      .after {
        animation-name: glitch-animation-2;
      }
    }

    @keyframes glitch-animation-1 {
      $steps: 20;
      @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
          clip: rect(random(150)+px, 900px, random(150)+px, 0);
        }
      }
    }

    @keyframes glitch-animation-2 {
      $steps: 20;
      @for $i from 0 through $steps {
        #{percentage($i*(1/$steps))} {
          clip: rect(random(150)+px, 900px, random(150)+px, 0);
        }
      }
    }
  }
}
