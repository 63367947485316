@import '~assets/scss/colors';

.logo {
  display: inline-block;
  width: 50px;
  height: 50px;
  color: $black;

  svg {
    width: 100%;
    height: 100%;
    object-fit: contain;

    path, polygon {
      fill: currentColor;
    }
  }
}
