$fontPath: '~assets/fonts/';

/* Consolas */

@font-face {
  font-family: Consolas;
  src: url($fontPath + 'consolas.ttf');
}

@font-face {
  font-family: Consolas;
  src: url($fontPath + 'consolasb.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Consolas;
  src: url($fontPath + 'consolasi.ttf');
  font-style: italic;
}

@font-face {
  font-family: Consolas;
  src: url($fontPath + 'consolasib.ttf');
  font-style: italic;
  font-weight: bold;
}

/* Montserrat */

@font-face {
  font-family: Montserrat;
  src: url($fontPath + 'Montserrat-Regular.ttf');
}

@font-face {
  font-family: Montserrat;
  src: url($fontPath + 'Montserrat-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Montserrat;
  src: url($fontPath + 'Montserrat-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Montserrat;
  src: url($fontPath + 'Montserrat-BoldItalic.ttf');
  font-style: italic;
  font-weight: bold;
}
