@import "~assets/scss/colors";

.history-item {
  margin-bottom: 30px;

  .date {
    border: 1px solid $blue;
    display: inline-block;
    padding: 2px 15px;
    font-size: 12px;
    color: $blue;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: -30px;
      transition: translateY(-50%);
      border-top: 1px solid $blue;
      width: 30px;
      height: 100%;
    }
  }

  h4 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
  }

  a {
    h4 {
      color: $blue;
    }
  }
}
