@import "~assets/scss/colors";

#picture-modal {
  text-align: center;

  img {
    width: 100%;
    margin-bottom: 20px;
  }
}
