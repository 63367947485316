@import "~assets/scss/colors";

#about {
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 70px;

  .profile-pic {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
    margin-right: 30px;
    vertical-align: top;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .desc {
    width: calc(100% - 150px);
    display: inline-block;
    text-align: justify;
    padding-top: 30px;
    padding-bottom: 100px;
  }

  @media (max-width: 767px) {
    .profile-pic {
      margin: 0 auto;
      display: block;
    }

    .desc {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
