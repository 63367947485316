@import "~assets/scss/colors";

#what-i-do {
  box-sizing: border-box;
  padding-top: 100px;
  padding-bottom: 70px;

  .row {
    margin-top: 30px;
  }

  .what-item {
    margin-bottom: 50px;

    .title {
      color: $blue;
      margin-bottom: 10px;

      svg {
        width: 30px;
        height: 30px;
        vertical-align: middle;
      }

      h4 {
        margin-left: 15px;
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 0;
      }
    }
  }

  .skills {
    .skill-wrapper {
      padding-top: 50px;
      position: relative;

      .rail {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: $alto;
      }

      .text {
        position: absolute;
        bottom: 6px;
        left: 0;
        color: $blue;
        font-weight: bold;
      }

      .car {
        position: absolute;
        bottom: 0;
        left: 0;
        height: 3px;
        background-color: $pink;

        .percentage {
          right: 0;
          left: auto;
          position: absolute;
          bottom: 6px;
          color: $blue;
          background-color: $white;
          z-index: 2;
          padding: 1px 3px;
        }
      }
    }
  }
}
