@import "~assets/scss/colors";

#home {
  padding-top: 300vh;

  footer {
    color: $alto;
    padding: 30px;
  }
}
