@import "~assets/scss/colors";

#app {
  h3 {
    text-transform: capitalize;
    color: $blue;
    font-weight: bold;
    margin-bottom: 30px;
    position: relative;
    display: inline-block;
    font-size: 24px;
    font-family: Montserrat;

    &:after {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      border-bottom: 24px solid $pink;
      border-right: 12px solid transparent;
      bottom: 5px;
      right: -20px;
    }
  }

  a {
    color: inherit;
    transition: all .2s ease-in-out;

    &:hover {
      color: $pink;
      text-decoration: none;
    }
  }
}
