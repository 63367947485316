@import "~assets/scss/colors";

.work-item {
  width: 100%;
  text-align: center;
  margin-bottom: 50px;

  .image-wrapper {
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    cursor: pointer;

    .image-inner-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;

      img {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        filter: saturate(0%);
        transition: all .2s ease-in-out;
      }
    }

    .overlay {
      position: absolute;
      width: calc(100% - 40px);
      height: calc(100% - 40px);
      opacity: 1;
      transition: all .2s ease-in-out;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-color: $blue;
      mix-blend-mode: color;

      &.pink {
        background-color: $pink;
        mix-blend-mode: multiply;
      }
    }

    svg {
      width: 36px !important;
      height: 36px;
      color: $white;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      opacity: 1;
      transition: all .2s ease-in-out;
    }

    &:hover {
      img {
        filter: saturate(100%);
      }

      .overlay {
        width: 0;
        height: 0;
        opacity: 0;
      }

      svg {
        opacity: 0;
      }
    }
  }

  svg {
    width: 36px !important;
    height: 36px;
    cursor: pointer;
    color: $blue;
    transition: all .2s ease-in-out;

    &:hover {
      color: $pink;
    }
  }

  h4 {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    transition: all .2s ease-in-out;
    margin-bottom: 3px;
    color: $blue;

    &:hover {
      color: $pink;
    }
  }
}
