@import "~assets/scss/colors";

#sound-player-modal {
  text-align: center;

  audio {
    &:active, &:focus {
      outline: none;
    }
  }

  .modal-body {
    padding-top: 50px;
  }
}
