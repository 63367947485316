@import "~assets/scss/colors";

#contacts {
  padding: 50px 0;

  .row {
    margin-top: 30px;
  }

  .contact-item {
    margin-bottom: 50px;

    .title {
      color: $blue;
      margin-bottom: 15px;

      svg {
        width: 30px;
        height: 30px;
        vertical-align: middle;
      }

      span {
        margin-left: 15px;
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        font-weight: bold;
      }
    }

    p {
      color: $black;
    }
  }
}
