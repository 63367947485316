@import '~assets/scss/colors';

header {
  padding: 9px 20px;
  position: fixed;
  top: 25px;
  left: 20px;
  width: calc(100% - 40px);
  height: 60px;
  z-index: 99999;
  box-sizing: border-box;
  display: flex;
  transition: all .2s ease-in-out;

  .logo-wrapper {
    &:active, &:focus {
      outline: none;
    }
  }

  .logo {
    width: 40px;
    height: 40px;
    color: $white;
    transition: all .2s ease-in-out;
  }

  .right {
    margin-left: auto;

    .nav-menu {
      display: inline-block;
      position: relative;
      top: 50%;
      right: 20px;
      margin: 0;
      padding: 0;
      list-style: none;
      color: $white;
      transform: translateY(-50%);

      li {
        display: inline-block;
        padding-left: 30px;
        padding-right: 30px;
        cursor: pointer;
        transition: all .2s ease-in-out;

        &:hover {
          color: $pink;
        }
      }

      @media(max-width: 767px) {
        position: fixed;
        right: 20px;
        top: 90px;
        text-align: right;
        transition: all .2s ease-in-out;
        height: 0;
        overflow: hidden;
        transform: initial;
        background-color: $blue;
        padding: 0 30px;

        li {
          display: block;
          padding: 0;
          padding-top: 10px;
          padding-bottom: 12px;
        }

        &.active {
          height: 190px;
        }
      }
    }

    .menu-icon {
      width: 40px;
      height: 30px;
      box-sizing: border-box;
      cursor: pointer;
      vertical-align: middle;
      display: none;
      background: none;
      border: 0;
      position: relative;
      top: 3px;

      @media(max-width: 767px) {
        display: inline-block;
      }

      .line {
        height: 2px;
        border-radius: 2px;
        width: 100%;
        background-color: $white;
        margin-top: 3px;
        margin-bottom: 4px;
        float: left;
        transition: all 0.2s ease-in-out;
      }

      &.active {
        .line {
          &.line-1 {
            transform: translateY(10px) rotate(45deg);
          }

          &.line-2 {
            transform: rotate(-45deg);
          }

          &.line-3 {
            width: 1;
            opacity: 0;
          }
        }
      }

      &:focus, &:active {
        border: 0;
        outline: none;
      }
    }
  }

  &.solid {
    background-color: $white;
    box-shadow: 0 0 1px 1px rgba(14, 16, 15, .1);
    top: 20px;
    left: 0;
    width: 100%;

    .logo {
      color: $blue;
    }

    .right {
      .nav-menu {
        color: $black;
      }
    }
  }
}
